import { User } from '@models/user';
import { client } from '@services/api-client';

const getUser = async ({ token }: { token: string }): Promise<User> => {
  const user = await client<User, undefined>('users/me', { token });

  return user;
};

export { getUser };
