import { IFieldsAndFilters } from '@models/common/api-client';
import { isEmpty } from '@utils/common';

const parseFilters = (obj: Record<string, any>): string => {
  const result = Object.keys(obj).reduce((acc, prev) => {
    if (!isEmpty(obj[prev])) {
      acc.push(
        `${prev}=${Array.isArray(obj[prev]) ? obj[prev].join(',') : obj[prev]}`,
      );
    }
    return acc;
  }, [] as string[]);

  return result.join('%26');
};

const parseFields = (arr: string[]): string => arr.join(',');

const parseFieldsAndFilters = (
  fields: string[],
  filters: Record<string, any>,
): { fields: string; filters: string } => ({
  fields: parseFields(fields),
  filters: parseFilters(filters),
});

const constructUrl = <T>(
  base: string,
  config: IFieldsAndFilters<T>,
): string => {
  const { fields = [], filters = {}, offset, limit } = config;
  let parsedUrl = base;
  const parsedFields = parseFields(fields);
  const parsedFilters = parseFilters(filters);

  if (!isEmpty(parsedFields)) {
    parsedUrl = `${parsedUrl}?fields=${parsedFields}`;
  }

  if (!isEmpty(parsedFilters)) {
    parsedUrl = `${parsedUrl}${
      !isEmpty(fields) ? '&' : '?'
    }filters=${parsedFilters}`;
  }

  if (offset) {
    parsedUrl = `${parsedUrl}${
      !isEmpty(fields) || !isEmpty(filters) ? '&' : '?'
    }offset=${offset}`;
  }

  if (limit) {
    parsedUrl = `${parsedUrl}${
      !isEmpty(fields) || !isEmpty(filters) ? '&' : '?'
    }limit=${limit}`;
  }

  return parsedUrl;
};
export { parseFields, parseFilters, parseFieldsAndFilters, constructUrl };
