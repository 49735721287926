import React from 'react';
import { ReactQueryConfig, ReactQueryConfigProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { Global } from '@emotion/core';

import { AuthProvider } from '@context/auth';
import { globalStyles } from '@styles/utils';

import { GiftProvider } from './gift';
import { HistoryProvider } from './history';
import { SessionProvider } from './session';

const queryConfig: ReactQueryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    retry: () => false,
  },
};

const AppProviders: React.FunctionComponent = ({ children }) => (
  <ReactQueryConfigProvider config={queryConfig}>
    <Global styles={globalStyles} />
    <HistoryProvider>
      <AuthProvider>
        <SessionProvider>
          <GiftProvider>{children}</GiftProvider>
        </SessionProvider>
      </AuthProvider>
    </HistoryProvider>
    <ReactQueryDevtools />
  </ReactQueryConfigProvider>
);

export default AppProviders;
