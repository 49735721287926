import { css } from '@emotion/core';
import styled from '@emotion/styled';

import * as colors from '@styles/colors';

import { large, medium, small } from './media-queries';

export const globalStyles = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    position: relative;
    font-family: 'sofia-pro', Raleway;
    font-weight: 300;

    position: relative;
  }

  body {
    background: white;
    font-size: 18px;
    color: ${colors.secondary};
    padding: 70px 0 0 0;
    letter-spacing: 0.2px;
  }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  select::-ms-expand {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    button.logo {
      z-index: 11;

      svg {
        height: 18px;
      }
    }
  }
`;

// Flex Layout
export const isFlex = { flex: 1 };
export const hasFlex = { display: 'flex' };
export const justifyCenter = { justifyContent: 'center' };
export const justifyBetween = { justifyContent: 'space-between' };
export const flexRows = {
  display: 'flex' as any,
  flexDirection: 'row' as any,
  flex: 1,
};
export const mDirectionColumn = {
  mobileColumn: {
    flexDirection: 'row',
    [small]: {
      flexDirection: 'column',
    },
  },
};

// Font Sizes
export const fontSize = {
  fZero: 0,
  fTen: { fontSize: '10px' },
  fEleven: { fontSize: '11px' },
  fTwelve: { fontSize: '12px' },
  fThirteen: { fontSize: '13px' },
  fFourteen: { fontSize: '14px' },
  fFifteen: { fontSize: '15px' },
  fSixteen: { fontSize: '16px' },
  fSeventeen: { fontSize: '17px' },
  fEighteen: { fontSize: '18px' },
  fTwentyOne: { fontSize: '21px' },
  fTwentyTwo: { fontSize: '22px' },
  fTwentyFour: { fontSize: '24px' },
  fTwentyEight: { fontSize: '28px' },
  fThirtyTwo: { fontSize: '32px' },
  hFour: { fontSize: '16px' },
  hThree: { fontSize: '18px' },
  hTwo: { fontSize: '21px' },
  hOne: { fontSize: '24px' },
  hOneXl: { fontSize: '28px' },
};

// Font Weights
export const fontWeight = {
  fwL: { fontWeight: 300 },
  fwR: { fontWeight: 400 },
  fwSb: { fontWeight: 500 },
  fwB: { fontWeight: 700 },
  fwEb: { fontWeight: 900 },
};

// Text Alignments
export const textAlignments = {
  textRight: { textAlign: 'right' } as const,
  textCenter: { textAlign: 'center' } as const,
  textLeft: { textAlign: 'left' } as const,
};

export const textTransform = {
  uppercase: { textTransform: 'uppercase' } as const,
  lowercase: { textTransform: 'lowercase' } as const,
};

// Item Alignments
export const itemAlignments = {
  alignsTop: { alignItems: 'start' } as const,
  alignsMiddle: { alignItems: 'center' } as const,
  alignsBottom: { alignItems: 'flex-end' } as const,
};

// Animate anything
export const animate = {
  transition: 'all 100ms ease-out',
};

// Flip
export const flip = {
  horizontal: { transform: 'scaleX(-1)' },
};

// Margins
export const margins = {
  auto: { margin: 'auto' },
  mFive: { margin: '5px' },
  mTen: { margin: '10px' },
  mFifteen: { margin: '15px' },
  mTwenty: { margin: '20px' },
  mTwentyFive: { margin: '25px' },
  mThirty: { margin: '30px' },
  mThirtyFive: { margin: '35px' },
  mForty: { margin: '40px' },
  mFortyFive: { margin: '45px' },
  mFifty: { margin: '50px' },
  mFiftyFive: { margin: '55px' },
  mSixty: { margin: '60px' },
};

// Margin top
export const marginTop = {
  mTZero: { marginTop: '0' },
  mtFive: { marginTop: '5px' },
  mtTen: { marginTop: '10px' },
  mtFifteen: { marginTop: '15px' },
  mtTwenty: { marginTop: '20px' },
  mtTwentyFive: { marginTop: '25px' },
  mtThirty: { marginTop: '30px' },
  mtThirtyFive: { marginTop: '35px' },
  mtForty: { marginTop: '40px' },
  mtFortyFive: { marginTop: '45px' },
  mtFifty: { marginTop: '50px' },
  mtFiftyFive: { marginTop: '55px' },
  mtSixty: { marginTop: '60px' },
};

// Margin bottom
export const marginBottom = {
  mbZero: { marginBottom: '0' },
  mbFive: { marginBottom: '5px' },
  mbSeven: { marginBottom: '7px' },
  mbTen: { marginBottom: '10px' },
  mbFifteen: { marginBottom: '15px' },
  mbTwenty: { marginBottom: '20px' },
  mbTwentyFive: { marginBottom: '25px' },
  mbThirty: { marginBottom: '30px' },
  mbThirtyFive: { marginBottom: '35px' },
  mbForty: { marginBottom: '40px' },
  mbFortyFive: { marginBottom: '45px' },
  mbFifty: { marginBottom: '50px' },
  mbFiftyFive: { marginBottom: '55px' },
  mbSixty: { marginBottom: '60px' },
};

// Margin right
export const marginRight = {
  mrZero: { marginRight: '0px' },
  mrFive: { marginRight: '5px' },
  mrTen: { marginRight: '10px' },
  mrFifteen: { marginRight: '15px' },
  mrTwenty: { marginRight: '20px' },
  mrTwentyFive: { marginRight: '25px' },
  mrThirty: { marginRight: '30px' },
  mrThirtyFive: { marginRight: '35px' },
  mrForty: { marginRight: '40px' },
  mrFortyFive: { marginRight: '45px' },
  mrFifty: { marginRight: '50px' },
  mrFiftyFive: { marginRight: '55px' },
  mrSixty: { marginRight: '60px' },
};

// Margin left
export const marginLeft = {
  mlFive: { marginLeft: '5px' },
  mlTen: { marginLeft: '10px' },
  mlFifteen: { marginLeft: '15px' },
  mlTwenty: { marginLeft: '20px' },
  mlTwentyFive: { marginLeft: '25px' },
  mlThirty: { marginLeft: '30px' },
  mlThirtyFive: { marginLeft: '35px' },
  mlForty: { marginLeft: '40px' },
  mlFortyFive: { marginLeft: '45px' },
  mlFifty: { marginLeft: '50px' },
  mlFiftyFive: { marginLeft: '55px' },
  mlSixty: { marginLeft: '60px' },
};

// Paddings
export const padding = {
  pFive: { padding: '5px' },
  pTen: { padding: '10px' },
  pFifteen: { padding: '15px' },
  pTwenty: { padding: '20px' },
  pTwentyFive: { padding: '25px' },
  pThirty: { padding: '30px' },
  pThirtyFive: { padding: '35px' },
  pForty: { padding: '40px' },
  pFortyFive: { padding: '45px' },
  pFifty: { padding: '50px' },
  pFiftyFive: { padding: '55px' },
  pSixty: { padding: '60px' },
};

// Padding top
export const paddingTop = {
  ptFive: { paddingTop: '5px' },
  ptEight: { paddingTop: '8px' },
  ptTen: { paddingTop: '10px' },
  ptFifteen: { paddingTop: '15px' },
  ptTwenty: { paddingTop: '20px' },
  ptTwentyFive: { paddingTop: '25px' },
  ptThirty: { paddingTop: '30px' },
  ptThirtyFive: { paddingTop: '35px' },
  ptForty: { paddingTop: '40px' },
  ptFortyFive: { paddingTop: '45px' },
  ptFifty: { paddingTop: '50px' },
  ptFiftyFive: { paddingTop: '55px' },
  ptSixty: { paddingTop: '60px' },
};

// Padding bottom
export const paddingBottom = {
  pbFive: { paddingBottom: '5px' },
  pbTen: { paddingBottom: '10px' },
  pbFifteen: { paddingBottom: '15px' },
  pbTwenty: { paddingBottom: '20px' },
  pbTwentyFive: { paddingBottom: '25px' },
  pbThirty: { paddingBottom: '30px' },
  pbThirtyFive: { paddingBottom: '35px' },
  pbForty: { paddingBottom: '40px' },
  pbFortyFive: { paddingBottom: '45px' },
  pbFifty: { paddingBottom: '50px' },
  pbFiftyFive: { paddingBottom: '55px' },
  pbSixty: { paddingBottom: '60px' },
  pbSeventy: { paddingBottom: '70px' },
};

// Padding right
export const paddingRight = {
  prZero: { paddingRight: '0px' },
  prFive: { paddingRight: '5px' },
  prTen: { paddingRight: '10px' },
  prFifteen: { paddingRight: '15px' },
  prTwenty: { paddingRight: '20px' },
  prTwentyFive: { paddingRight: '25px' },
  prThirty: { paddingRight: '30px' },
  prThirtyFive: { paddingRight: '35px' },
  prForty: { paddingRight: '40px' },
  prFortyFive: { paddingRight: '45px' },
  prFifty: { paddingRight: '50px' },
  prFiftyFive: { paddingRight: '55px' },
  prSixty: { paddingRight: '60px' },
};

// Padding left
export const paddingLeft = {
  plZero: { paddingLeft: '0px' },
  plFive: { paddingLeft: '5px' },
  plTen: { paddingLeft: '10px' },
  plFifteen: { paddingLeft: '15px' },
  plTwenty: { paddingLeft: '20px' },
  plTwentyFive: { paddingLeft: '25px' },
  plThirty: { paddingLeft: '30px' },
  plThirtyFive: { paddingLeft: '35px' },
  plForty: { paddingLeft: '40px' },
  plFortyFive: { paddingLeft: '45px' },
  plFifty: { paddingLeft: '50px' },
  plFiftyFive: { paddingLeft: '55px' },
  plSixty: { paddingLeft: '60px' },
};

export const positionAbsolute = {
  topRight: { position: 'absolute', top: '0', right: '0' },
  topLeft: { position: 'absolute', top: '0', left: '0' },
  bottomLeft: { position: 'absolute', bottom: '0', left: '0' },
  bottomRight: { position: 'absolute', bottom: '0', right: '0' },
  bottomMiddle: {
    position: 'absolute' as any,
    bottom: '0',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  rightMiddle: {
    position: 'absolute',
    top: '50%',
    right: '0',
    transform: 'translateY(-50%)',
  },
  centerMiddle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
  },
};

export const cursor = { cursor: 'pointer' };

export const fonts = {
  bGBold: {
    fontFamily: 'brandon-grotesque',
    fontWeight: 700,
    fontStyle: 'normal',
  },

  bGMedium: {
    fontFamily: 'brandon-grotesque',
    fontWeight: 500,
    fontStyle: 'normal',
  },
  bGRegular: {
    fontFamily: 'brandon-grotesque',
    fontWeight: 400,
    fontStyle: 'normal',
  },
  spLight: {
    fontFamily: 'sofia-pro',
    fontWeight: 300,
    fontStyle: 'normal',
  },
  spRegular: {
    fontFamily: 'sofia-pro',
    fontWeight: 400,
    fontStyle: 'normal',
  },
  spMedium: {
    fontFamily: 'sofia-pro',
    fontWeight: 500,
    fontStyle: 'normal',
  },
  spSemiBold: {
    fontFamily: 'sofia-pro',
    fontWeight: 600,
    fontStyle: 'normal',
  },
  spBold: {
    fontFamily: 'sofia-pro',
    fontWeight: 700,
    fontStyle: 'normal',
  },
  infoPara: {
    fontSize: '16px',
    lineHeight: '25px',
  },
  infoCommon: {
    fontSize: '14px',
    lineHeight: '25.5px',
    letterSpacing: '0.2px',
    fontFamily: 'sofia-pro',
    fontWeight: 400,
    fontStyle: 'normal',
  },
  palmerLake: {
    fontSize: '36px',
    lineHeight: '25.5px',
    letterSpacing: '0.9px',
    fontFamily: 'palmer-lake',
  },
};

export const bdrBtm = {
  borderBottom: '1px solid',
  borderColor: colors.border,
};

export const bdrBtmDanger = {
  borderBottom: '1px solid',
  borderColor: colors.danger,
};
export const bdr = '4px';

export const maxWidth = '1200px';
export const mediumWidth = '930px';
export const maxWidthPageContent = '526px';

export const colorBarDesktop =
  'linear-gradient(to right,  #cddbe3 10%,#e7e1d9 10%,#e7e1d9 20%,#e8d0cd 20%,#e8d0cd 30%,#a6c3d2 30%,#a6c3d2 40%,#cddbe3 40%,#cddbe3 50%,#E7E1D9 50%,#E7E1D9 60%,#E8D0CD 60%,#E8D0CD 70%,#A6C3D2 70%,#A6C3D2 80%,#CDDBE3 80%,#CDDBE3 90%,#E7E1D9 90%,#E7E1D9 100%)';
export const colorBarMob =
  'linear-gradient(to right,  #cddbe3 20%,#e7e1d9 20%,#e7e1d9 40%,#e8d0cd 40%,#e8d0cd 60%,#a6c3d2 60%,#a6c3d2 80%,#cddbe3 80%,#cddbe3 100%)';

export const contentWidth = {
  width80p: { width: '80%' },
  width100p: { width: '100%' },
};

export const maxListHeight = {
  h50vh: { maxHeight: '50vh', overflow: 'auto' },
  h45vh: { maxHeight: '45vh', overflow: 'auto' },
};

export const maxContentWidth = {
  width400: { maxWidth: '400px', margin: 'auto' },
  width80p: { maxWidth: '80%' },
  width90p: { maxWidth: '90%' },
  width100p: { maxWidth: '100%' },
};

export const textDecorations = {
  underline: { textDecoration: 'underline' } as const,
  none: { textDecoration: 'none' } as const,
};

export const letterSpacing = {
  small: { letterSpacing: '0.18px' },
  normal: { letterSpacing: '1.45px' },
  medium: { letterSpacing: '1.66px' },
  large: { letterSpacing: '1.77px' },
};

export const grid = {
  twoGrid: { display: 'grid', gridTemplateColumns: '1fr 1fr' },
  oneGridMob: {
    display: 'grid',
    [small]: {
      gridTemplateColumns: '1fr',
    },
  },
};

export const gap = {
  gap5: {
    gap: 5,
  },
  gap15: {
    gap: 15,
  },
  gap20: {
    gap: 20,
  },
  gap25: {
    gap: 25,
  },
  gap30: {
    gap: 30,
  },
  gap40: {
    gap: 40,
  },
  mobGap0: {
    [small]: {
      gap: 0,
    },
  },
};

// Hide Elements

export const IsHiddenMobile = styled.div({
  [small]: {
    display: 'none',
  },
});

export const IsHiddenIpad = styled.div({
  [medium]: {
    display: 'none',
  },
});

export const IsHiddenDesktop = styled.div({
  [large]: {
    display: 'none',
  },
});
