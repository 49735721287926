import { QueryConfig, useMutation } from 'react-query';

import { useClient } from '@context/auth';
import { SuccessResponse } from '@models/common/api-client';
import { ErrorType } from '@models/common/async-hook';
import {
  CreateInvitePayload,
  NudgeDecisionPayload,
} from '@models/invite-friend';
import { MutationResponse } from '@models/react-query';
import { client } from '@services/api-client';

const REFERRAL_LINK = 'referralLink';

const useCreateInviteLink = (
  config: QueryConfig<SuccessResponse, ErrorType | null> = {},
): MutationResponse<SuccessResponse> => {
  const authenticatedClient = useClient();

  return useMutation(
    () => authenticatedClient('users/create-invite-link', { method: 'POST' }),
    { ...config },
  );
};

const createInvite = (
  authenticatedClient: typeof client,
  payload: CreateInvitePayload,
): Promise<SuccessResponse> =>
  authenticatedClient<SuccessResponse, CreateInvitePayload>(
    'users/email-link',
    { data: payload },
  );

const useCreateInvite = (
  config: QueryConfig<SuccessResponse, ErrorType | null> = {},
): MutationResponse<SuccessResponse, CreateInvitePayload> => {
  const authenticatedClient = useClient();

  return useMutation(
    (payload: CreateInvitePayload) =>
      createInvite(authenticatedClient, payload),
    { ...config },
  );
};

const postNudgeDecision = (
  authenticatedClient: typeof client,
  payload: NudgeDecisionPayload,
): Promise<SuccessResponse> =>
  authenticatedClient<SuccessResponse, NudgeDecisionPayload>('users/nudge', {
    data: payload,
  });

const usePostNudgeDecision = (
  config: QueryConfig<SuccessResponse, ErrorType | null> = {},
): MutationResponse<SuccessResponse, NudgeDecisionPayload> => {
  const authenticatedClient = useClient();

  return useMutation(
    (payload: NudgeDecisionPayload) =>
      postNudgeDecision(authenticatedClient, payload),
    { ...config },
  );
};

export {
  REFERRAL_LINK,
  useCreateInvite,
  useCreateInviteLink,
  usePostNudgeDecision,
};
