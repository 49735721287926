import React from 'react';

import { useAsync } from '@hooks/use-async';
import {
  AuthContextValue,
  AuthProviderProps,
  LoginForm,
  RegisterForm,
  ResetPasswordPayload,
} from '@models/auth';
import { User } from '@models/user';
import { client } from '@services/api-client';
import * as auth from '@services/auth';
import { getUser } from '@services/user';

const fetchUser = async () => {
  let user = null;

  const token = auth.getToken();
  if (token) {
    user = await getUser({ token });
  }
  return user;
};

const AuthContext = React.createContext<AuthContextValue | undefined>(
  undefined,
);
AuthContext.displayName = 'AuthContext';

const AuthProvider: React.FunctionComponent<AuthProviderProps> = ({
  children,
}) => {
  const {
    data: user,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync<User>();

  React.useEffect(() => {
    run(fetchUser());
  }, [run]);

  const login = React.useCallback(
    async (form: LoginForm) =>
      auth.login(form).then((_user: User) => setData(_user)),
    [setData],
  );

  const register = React.useCallback(
    async (form: RegisterForm) =>
      auth.register(form).then((_user: User) => setData(_user)),
    [setData],
  );

  const logout = React.useCallback(async () => {
    try {
      await auth.logout();
    } finally {
      setData(null);
    }
  }, [setData]);

  const forgotPassword = React.useCallback(async (email: string) => {
    await auth.forgotPassword(email);
  }, []);

  const resetPassword = React.useCallback(
    async (payload: ResetPasswordPayload) => {
      await auth.resetPassword(payload);
    },
    [],
  );

  const refresh = React.useCallback(
    () =>
      fetchUser().then((_user: User | null) => {
        if (_user) {
          setData(_user);
        }
      }),
    [setData],
  );

  const value: AuthContextValue = React.useMemo(
    () => ({
      user,
      login,
      logout,
      register,
      forgotPassword,
      resetPassword,
      refresh,
      status,
      error,
      isIdle,
      isLoading,
      isError,
      isSuccess,
    }),
    [
      isError,
      isIdle,
      isLoading,
      isSuccess,
      login,
      logout,
      register,
      forgotPassword,
      resetPassword,
      refresh,
      status,
      user,
      error,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = (): AuthContextValue => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

const useClient = (): typeof client => {
  const token = auth.getToken() as string;

  return React.useCallback(
    (endpoint, config) => client(endpoint, { ...config, token }),
    [token],
  );
};

export { AuthProvider, useAuth, useClient };
