import { GiftActionTypes, GiftReducerType, GiftState } from '@models/gift';

export const giftInitialState: GiftState = {
  selectedGiftType: null,
  selectedDuration: null,
  giftDeliveryInfo: null,
  paymentMethodId: null,
};

const giftReducer: GiftReducerType = (state, action) => {
  switch (action.type) {
    case GiftActionTypes.SET_SELECTED_GIFT_TYPE:
      return {
        ...state,
        selectedGiftType: action.payload,
      };

    case GiftActionTypes.SET_SELECTED_GIFT_DURATION:
      return {
        ...state,
        selectedDuration: action.payload,
      };

    case GiftActionTypes.SET_GIFT_DELIVERY_INFO:
      return {
        ...state,
        giftDeliveryInfo: action.payload,
      };

    case GiftActionTypes.SET_PAYMENT_METHOD_ID:
      return {
        ...state,
        paymentMethodId: action.payload,
      };

    case GiftActionTypes.RESET:
      return giftInitialState;

    default:
      return state;
  }
};

export default giftReducer;
