import Cookies from 'js-cookie';

import {
  AuthReturn,
  LoginForm,
  RegisterForm,
  ResetPasswordPayload,
} from '@models/auth';
import { SuccessResponse } from '@models/common/api-client';
import { User } from '@models/user';
import { client } from '@services/api-client';
import { clearUserData } from '@utils/common';
import { cookieTokenKey } from '@utils/constants';

const getToken = (): string | undefined => Cookies.get(cookieTokenKey);

const handleAuthResponse = (user: AuthReturn): User => {
  Cookies.set(cookieTokenKey, user.sessionToken, { expires: 90 });

  // This is just to make sure the types stay consistent as the auth calls would have the token, and the user calls don't
  const { sessionToken, ...rest } = user;
  return rest;
};

const login = async (data: LoginForm): Promise<User> => {
  const result = await client<AuthReturn, LoginForm>('users/login', { data });
  return handleAuthResponse(result);
};

const register = async (data: RegisterForm): Promise<User> => {
  const result = await client<AuthReturn, RegisterForm>('users', { data });
  return handleAuthResponse(result);
};

const logout = async (): Promise<void> => {
  try {
    await client<SuccessResponse, undefined>(`users/logout`, {
      method: 'DELETE',
      token: getToken(),
    });
  } finally {
    clearUserData();
  }
};

const forgotPassword = async (email: string): Promise<void> => {
  await client<SuccessResponse, undefined>(
    `users/forgot-password?email=${encodeURIComponent(email)}`,
  );
};

const resetPassword = (payload: ResetPasswordPayload): Promise<any> =>
  client<User, ResetPasswordPayload>('users/reset-password', {
    data: { ...payload },
    method: 'POST',
  });

export { getToken, login, register, logout, forgotPassword, resetPassword };
