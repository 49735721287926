import React from 'react';
import { queryCache, QueryConfig, useMutation, useQuery } from 'react-query';

import { useAuth, useClient } from '@context/auth';
import { IFieldsAndFilters } from '@models/common/api-client';
import { ErrorType } from '@models/common/async-hook';
import { Location } from '@models/location';
import { MutationResponse, QueryResponse } from '@models/react-query';
import { AvailableSlots } from '@models/session/choose-therapist';
import { LastSessionDetail } from '@models/session/common';
import { CreateSessionPayload } from '@models/session/confirm';
import { Insurance, InsuranceApiResponse } from '@models/session/insurance';
import {
  NewGuestIntake,
  NewGuestIntakeApiResponse,
} from '@models/session/new-guest-intake';
import {
  OkayCheckAnswer,
  OkayCheckAnswers,
  OkayCheckAnswersPayload,
  OkayCheckQuestionsResponse,
} from '@models/session/okay-check';
import { SessionPaymentMethod } from '@models/session/payment-methods';
import {
  PostBookingTodos,
  SignWaiverPayload,
} from '@models/session/post-booking-flow';
import { Price, PricingApiResponse } from '@models/session/pricing';
import { Session, SessionStatus } from '@models/session/session';
import { SignWaiver } from '@models/session/sign-waiver';
import { Slot } from '@models/slots';
import { Specialty } from '@models/specialty';
import { client } from '@services/api-client';
import { constructUrl, parseFilters } from '@utils/api';
import { castArray, pickBy } from '@utils/common';

const fetchLocations = (
  config: IFieldsAndFilters<Partial<Location>> = {},
): Promise<Location[]> => {
  const requestUrl = constructUrl<Partial<Location>>('locations', config);
  return client<Location, undefined>(requestUrl);
};

const useLocations = (
  config: QueryConfig<Location[], ErrorType | null> = {},
): QueryResponse<Location[]> =>
  useQuery({
    queryKey: 'locations',
    queryFn: () => fetchLocations(),
    config: {
      staleTime: 1000 * 60 * 60,
      onSuccess: (locations: Location[]) => {
        locations.forEach(location => {
          queryCache.setQueryData(['locations', location.id], location);
        });
      },
      ...config,
    },
  });

const useLocationDetail = (
  locationId: number,
  config: QueryConfig<Location, ErrorType | null> = {},
): QueryResponse<Location> =>
  useQuery({
    queryKey: ['locations', locationId],
    queryFn: () =>
      fetchLocations({
        filters: { id: locationId },
      }).then(t => t[0]),
    config: {
      staleTime: 1000 * 60 * 60,
      ...config,
    },
  });

const prefetchLocationDetail = async (locationId: number): Promise<void> => {
  await queryCache.prefetchQuery(['locations', locationId], () =>
    fetchLocations({
      filters: { id: locationId },
    }).then(t => t[0]),
  );
};

const fetchSpecialties = (): Promise<Specialty[]> =>
  client<Specialty, undefined>('specialties');

const useSpecialties = (
  config: QueryConfig<Specialty[], ErrorType | null> = {},
): QueryResponse<Specialty[]> =>
  useQuery({ queryKey: 'specialties', queryFn: fetchSpecialties, config });

const fetchAvailableSlots = ({
  filters,
}: Pick<AvailableSlots, 'filters'>): Promise<Slot[]> =>
  client<Slot, undefined>(
    `sessions/availability?filters=${parseFilters(pickBy(filters))}`,
  );

const useAvailableSlots = (
  selectedDate: string | null,
  { filters, config }: AvailableSlots,
): QueryResponse<Slot[]> =>
  useQuery({
    queryKey: ['slots', selectedDate, { filters }],
    queryFn: () => fetchAvailableSlots({ filters }),
    config,
  });

const fetchLastSessionDetail = (
  authenticatedClient: typeof client,
): Promise<LastSessionDetail> =>
  authenticatedClient<LastSessionDetail, undefined>('users/last-info');

const useLastSessionDetail = (
  config: QueryConfig<LastSessionDetail, ErrorType | null> = {},
): QueryResponse<LastSessionDetail> => {
  const authenticatedClient = useClient();

  return useQuery({
    queryKey: 'lastSessionDetail',
    queryFn: () => fetchLastSessionDetail(authenticatedClient),
    config,
  });
};

const usePrefetchLastSessionDetail = (): (() => void) => {
  const authenticatedClient = useClient();

  return React.useCallback(async () => {
    queryCache.removeQueries('lastSessionDetail');
    await queryCache.prefetchQuery('lastSessionDetail', () =>
      fetchLastSessionDetail(authenticatedClient),
    );
  }, [authenticatedClient]);
};

const createSession = (
  authenticatedClient: typeof client,
  payload: CreateSessionPayload,
): Promise<Session> =>
  authenticatedClient<Session, CreateSessionPayload>('sessions', {
    data: payload,
  });

const useCreateSession = (
  config: QueryConfig<Session, ErrorType | null> = {},
): MutationResponse<Session, CreateSessionPayload> => {
  const authenticatedClient = useClient();
  const { refresh } = useAuth();

  return useMutation(
    (payload: CreateSessionPayload) =>
      createSession(authenticatedClient, payload),
    { ...config, onSettled: () => refresh() },
  );
};

const fetchPostBookingTodos = (
  authenticatedClient: typeof client,
  config: IFieldsAndFilters<Partial<PostBookingTodos>> = {},
): Promise<PostBookingTodos> => {
  const requestUrl = constructUrl<Partial<PostBookingTodos>>('users/to-do', {
    ...config,
  });

  return authenticatedClient<PostBookingTodos, undefined>(requestUrl);
};

const usePostBookingTodos = (
  config: QueryConfig<PostBookingTodos, ErrorType | null> = {},
): QueryResponse<PostBookingTodos> => {
  const authenticatedClient = useClient();

  return useQuery({
    queryKey: 'postBookingTodos',
    queryFn: () => fetchPostBookingTodos(authenticatedClient),
    config,
  });
};

const usePrefetchPostBookingTodos = (): (() => void) => {
  const authenticatedClient = useClient();

  return React.useCallback(async () => {
    queryCache.removeQueries('postBookingTodos');
    await queryCache.prefetchQuery('postBookingTodos', () =>
      fetchPostBookingTodos(authenticatedClient),
    );
  }, [authenticatedClient]);
};

const fetchSignWaiver = (
  authenticatedClient: typeof client,
  waiverToken?: string,
): Promise<SignWaiver> => {
  const requestUrl = waiverToken
    ? `users/waiver?waiverToken=${waiverToken}`
    : 'users/waiver';
  return authenticatedClient<SignWaiver, undefined>(requestUrl);
};

const useSignWaiver = (
  waiverToken?: string,
  config: QueryConfig<SignWaiver, ErrorType | null> = {},
): QueryResponse<SignWaiver> => {
  const authenticatedClient = useClient();

  return useQuery({
    queryKey: 'waiver',
    queryFn: () => fetchSignWaiver(authenticatedClient, waiverToken),
    config,
  });
};

const usePrefetchSignWaiver = (): (() => void) => {
  const authenticatedClient = useClient();

  return React.useCallback(async () => {
    queryCache.removeQueries('waiver');
    await queryCache.prefetchQuery('waiver', () =>
      fetchSignWaiver(authenticatedClient),
    );
  }, [authenticatedClient]);
};

const useCreateSignWaiver = (
  config: QueryConfig<SignWaiverPayload, ErrorType | null> = {},
): MutationResponse<SignWaiverPayload> => {
  const authenticatedClient = useClient();

  return useMutation(
    (payload: SignWaiverPayload) =>
      authenticatedClient('users/waiver', { data: payload }),
    { ...config },
  );
};

const fetchNewGuestIntake = (
  authenticatedClient: typeof client,
  config: IFieldsAndFilters<Partial<NewGuestIntakeApiResponse>> = {},
): Promise<NewGuestIntakeApiResponse> => {
  const requestUrl = constructUrl<Partial<NewGuestIntake>>(
    'users/new-guest-intake',
    { ...config },
  );
  return authenticatedClient<NewGuestIntakeApiResponse, undefined>(
    requestUrl,
  ).then((intakes: NewGuestIntakeApiResponse[]) => intakes[0] || {});
};

const useNewGuestIntake = (
  config: QueryConfig<NewGuestIntakeApiResponse, ErrorType | null> = {},
): QueryResponse<NewGuestIntakeApiResponse> => {
  const authenticatedClient = useClient();
  const { user } = useAuth();

  return useQuery({
    queryKey: 'newGuestIntake',
    queryFn: () =>
      fetchNewGuestIntake(authenticatedClient, {
        filters: { userId: user?.id },
      }),
    config,
  });
};

const usePrefetchNewGuestIntake = (): (() => void) => {
  const authenticatedClient = useClient();
  const { user } = useAuth();

  return React.useCallback(async () => {
    queryCache.removeQueries('newGuestIntake');
    await queryCache.prefetchQuery('newGuestIntake', () =>
      fetchNewGuestIntake(authenticatedClient, {
        filters: { userId: user?.id },
      }),
    );
  }, [authenticatedClient, user?.id]);
};

const usePostNewGuestIntake = (
  config: QueryConfig<NewGuestIntakeApiResponse, ErrorType | null> = {},
): MutationResponse<NewGuestIntakeApiResponse, Partial<NewGuestIntake>> => {
  const authenticatedClient = useClient();

  return useMutation(
    (payload: Partial<NewGuestIntake>) =>
      authenticatedClient<NewGuestIntakeApiResponse, Partial<NewGuestIntake>>(
        'users/new-guest-intake',
        { data: payload },
      ),
    { ...config },
  );
};

const fetchInsurance = (
  authenticatedClient: typeof client,
  config: IFieldsAndFilters<Partial<InsuranceApiResponse>> = {},
): Promise<InsuranceApiResponse> => {
  const requestUrl = constructUrl<Partial<Insurance>>('users/insurance', {
    ...config,
  });
  return authenticatedClient<InsuranceApiResponse, undefined>(requestUrl).then(
    (insurance: InsuranceApiResponse[]) => insurance[0] || {},
  );
};

const useInsurance = (
  config: QueryConfig<InsuranceApiResponse, ErrorType | null> = {},
): QueryResponse<InsuranceApiResponse> => {
  const authenticatedClient = useClient();
  const { user } = useAuth();

  return useQuery({
    queryKey: 'insurance',
    queryFn: () =>
      fetchInsurance(authenticatedClient, {
        filters: { userId: user?.id },
      }),
    config,
  });
};

const usePrefetchInsurance = (): (() => void) => {
  const authenticatedClient = useClient();
  const { user } = useAuth();

  return React.useCallback(async () => {
    queryCache.removeQueries('insurance');
    await queryCache.prefetchQuery('insurance', () =>
      fetchInsurance(authenticatedClient, {
        filters: { userId: user?.id },
      }),
    );
  }, [authenticatedClient, user?.id]);
};

const usePostInsurance = (
  config: QueryConfig<InsuranceApiResponse, ErrorType | null> = {},
): MutationResponse<InsuranceApiResponse, Partial<Insurance>> => {
  const authenticatedClient = useClient();

  return useMutation(
    (payload: Partial<Insurance>) =>
      authenticatedClient<InsuranceApiResponse, Partial<Insurance>>(
        'users/insurance',
        { data: payload },
      ),
    { ...config },
  );
};

const fetchOkayCheckQuestions = (
  authenticatedClient: typeof client,
): Promise<OkayCheckQuestionsResponse> =>
  authenticatedClient<OkayCheckQuestionsResponse, undefined>(
    'users/okay-check/questions',
  );

const useOkayCheckQuestions = (
  config: QueryConfig<OkayCheckQuestionsResponse, ErrorType | null> = {},
): QueryResponse<OkayCheckQuestionsResponse> => {
  const authenticatedClient = useClient();

  return useQuery({
    queryKey: 'okayCheckQuestions',
    queryFn: () => fetchOkayCheckQuestions(authenticatedClient),
    config,
  });
};

const usePrefetchOkayCheckQuestions = (): (() => void) => {
  const authenticatedClient = useClient();

  return React.useCallback(async () => {
    queryCache.removeQueries('okayCheckQuestions');
    await queryCache.prefetchQuery('okayCheckQuestions', () =>
      fetchOkayCheckQuestions(authenticatedClient),
    );
  }, [authenticatedClient]);
};

const fetchOkayCheckAnswers = (
  authenticatedClient: typeof client,
  config: IFieldsAndFilters<Partial<OkayCheckAnswer>> = {},
): Promise<OkayCheckAnswers> => {
  const requestUrl = constructUrl<Partial<OkayCheckAnswer>>(
    'users/okay-check/answers',
    { ...config },
  );

  return authenticatedClient<OkayCheckAnswers, undefined>(requestUrl);
};

const useOkayCheckAnswers = (
  config: QueryConfig<OkayCheckAnswer, ErrorType | null> = {},
): QueryResponse<OkayCheckAnswers> => {
  const authenticatedClient = useClient();

  return useQuery({
    queryKey: 'okayCheckAnswers',
    queryFn: () => fetchOkayCheckAnswers(authenticatedClient),
    config,
  });
};

const usePrefetchOkayCheckAnswers = (): (() => void) => {
  const authenticatedClient = useClient();

  return React.useCallback(async () => {
    queryCache.removeQueries('okayCheckAnswers');
    await queryCache.prefetchQuery('okayCheckAnswers', () =>
      fetchOkayCheckAnswers(authenticatedClient),
    );
  }, [authenticatedClient]);
};

const usePostOkayCheckAnswers = (
  config: QueryConfig<OkayCheckAnswer, ErrorType | null> = {},
): MutationResponse<any> => {
  const authenticatedClient = useClient();

  return useMutation(
    (payload: OkayCheckAnswer) =>
      authenticatedClient<OkayCheckAnswersPayload, OkayCheckAnswersPayload>(
        'users/okay-check/answers',
        {
          data: {
            answers: castArray(payload),
          },
        },
      ).then(res => res.answers),
    { ...config },
  );
};

const fetchSessions = (
  authenticatedClient: typeof client,
  config: IFieldsAndFilters<Partial<Session>> = {},
): Promise<Session[]> => {
  const defaultFields = [
    'therapist.id',
    'therapist.firstName',
    'therapist.imageUrl',
  ];
  const defaultFilters = {
    sortField: 'id',
    sortOrder: 'desc',
  };

  const requestUrl = constructUrl<Partial<Session>>('sessions', {
    ...config,
    fields: [...defaultFields, ...(config.fields || [])],
    filters: { ...defaultFilters, ...(config.filters || {}) },
    limit: config.limit || 100,
  });
  return authenticatedClient<Session, undefined>(requestUrl);
};

const useSessions = (
  variant: SessionStatus,
  config: QueryConfig<Session[], ErrorType | null> = {},
): QueryResponse<Session[]> => {
  const authenticatedClient = useClient();

  return useQuery({
    queryKey: ['sessions', variant],
    queryFn: () =>
      fetchSessions(authenticatedClient, {
        filters: {
          'status.in':
            variant === SessionStatus.UPCOMING
              ? [SessionStatus.UPCOMING]
              : [SessionStatus.COMPLETED, SessionStatus.NO_SHOW],
          sortField: 'startDateTime',
          sortOrder: variant === SessionStatus.UPCOMING ? 'asc' : 'desc',
        },
      } as IFieldsAndFilters<Partial<Session>>),
    config,
  });
};

const useSessionDetail = (
  sessionId: number,
  config: QueryConfig<Session, ErrorType | null> = {},
): QueryResponse<Session> => {
  const authenticatedClient = useClient();

  return useQuery({
    queryKey: ['sessions', sessionId],
    queryFn: () =>
      fetchSessions(authenticatedClient, {
        fields: ['therapist.*', 'location.*'],
        filters: {
          id: sessionId,
        },
      }).then((sessions: Session[]) => sessions[0]),
    config,
  });
};

const useCancelSession = (
  config: QueryConfig<Session, ErrorType | null> = {},
): MutationResponse<Session, number> => {
  const authenticatedClient = useClient();

  return useMutation(
    (sessionId: number) =>
      authenticatedClient<Session, undefined>(`sessions/${sessionId}/cancel`, {
        method: 'PUT',
      }),
    {
      onMutate: sessionId => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        queryCache.cancelQueries('sessions');

        // Snapshot the previous value
        const previousSessions: Session[] = queryCache.getQueryData([
          'sessions',
          'upcoming',
        ]) as Session[];
        const previousSession: Session = queryCache.getQueryData([
          'sessions',
          sessionId,
        ]) as Session;

        // Optimistically update to the new value
        queryCache.setQueryData(['sessions', sessionId], {
          ...previousSession,
          status: 'canceled',
        });

        let updatedSessions = [...(previousSessions || [])];

        updatedSessions = updatedSessions.filter(
          session => session.id !== sessionId,
        );

        queryCache.setQueryData(['sessions', 'upcoming'], updatedSessions);

        // Return the snapshotted value
        return () => {
          queryCache.setQueryData(['sessions', sessionId], previousSession);
          queryCache.setQueryData(['sessions', 'upcoming'], previousSessions);
        };
      },
      onSettled: () => {
        queryCache.invalidateQueries('sessions');
      },
      ...config,
    },
  );
};

const fetchPricing = (
  authenticatedClient: typeof client,
  config: IFieldsAndFilters<Partial<Price>> = {},
): Promise<PricingApiResponse> => {
  const defaultFilters = { isApplicable: true };
  const requestUrl = constructUrl('pricing', {
    ...config,
    filters: { ...defaultFilters, ...(config.filters || {}) },
  });
  return authenticatedClient<PricingApiResponse, undefined>(requestUrl);
};

const usePricing = (
  config: QueryConfig<PricingApiResponse, ErrorType | null> = {},
): QueryResponse<PricingApiResponse> => {
  const authenticatedClient = useClient();

  return useQuery({
    queryKey: 'pricing',
    queryFn: () =>
      fetchPricing(authenticatedClient, {
        filters: { isApplicable: true },
      }),
    config,
  });
};

const usePrefetchPricing = (): (() => void) => {
  const authenticatedClient = useClient();

  return React.useCallback(async () => {
    queryCache.removeQueries('pricing');
    await queryCache.prefetchQuery('pricing', () =>
      fetchPricing(authenticatedClient),
    );
  }, [authenticatedClient]);
};

const fetchSessionPaymentMethods = (): Promise<SessionPaymentMethod[]> => {
  const requestUrl = 'session-payment-methods';

  return client<SessionPaymentMethod[], undefined>(requestUrl);
};

const useSessionPaymentMethods = (
  config: QueryConfig<SessionPaymentMethod[], ErrorType | null> = {},
): QueryResponse<SessionPaymentMethod[]> =>
  useQuery({
    queryKey: 'session-payment-methods',
    queryFn: () => fetchSessionPaymentMethods(),
    config,
  });

const usePrefetchSessionPaymentMethods = (): (() => void) =>
  React.useCallback(async () => {
    queryCache.removeQueries('session-payment-methods');
    await queryCache.prefetchQuery('session-payment-methods', () =>
      fetchSessionPaymentMethods(),
    );
  }, []);

const fetchSessionPricing = (
  authenticatedClient: typeof client,
): Promise<Price> => {
  const requestUrl = 'session-pricing';

  return authenticatedClient<Price, undefined>(requestUrl);
};

const useSessionPricing = (
  config: QueryConfig<Price, ErrorType | null> = {},
): QueryResponse<Price> => {
  const authenticatedClient = useClient();

  return useQuery({
    queryKey: 'session-pricing',
    queryFn: () => fetchSessionPricing(authenticatedClient),
    config,
  });
};
export {
  fetchLocations,
  useLocations,
  fetchSpecialties,
  useSpecialties,
  fetchAvailableSlots,
  useAvailableSlots,
  useLocationDetail,
  useLastSessionDetail,
  usePrefetchLastSessionDetail,
  createSession,
  useCreateSession,
  prefetchLocationDetail,
  usePostBookingTodos,
  useSignWaiver,
  usePrefetchSignWaiver,
  useCreateSignWaiver,
  useNewGuestIntake,
  usePostNewGuestIntake,
  usePrefetchNewGuestIntake,
  useInsurance,
  usePrefetchInsurance,
  usePostInsurance,
  useOkayCheckQuestions,
  usePrefetchOkayCheckQuestions,
  useOkayCheckAnswers,
  usePrefetchOkayCheckAnswers,
  usePostOkayCheckAnswers,
  usePrefetchPostBookingTodos,
  useSessions,
  useSessionDetail,
  fetchPricing,
  usePricing,
  usePrefetchPricing,
  useCancelSession,
  fetchSessionPaymentMethods,
  useSessionPaymentMethods,
  usePrefetchSessionPaymentMethods,
  useSessionPricing,
};
