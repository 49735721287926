import React from 'react';

import usePersistentReducer from '@hooks/use-persistent-reducer';
import { GiftDispatch, GiftState } from '@models/gift';
import giftReducer, { giftInitialState } from '@reducers/gift';

const GiftStateContext = React.createContext<GiftState | undefined>(undefined);
const GiftDispatchContext = React.createContext<GiftDispatch | undefined>(
  undefined,
);

const GiftProvider: React.FunctionComponent = ({ children }) => {
  const [state, dispatch] = usePersistentReducer(
    giftReducer,
    giftInitialState,
    'gift',
  );
  return (
    <GiftStateContext.Provider value={state}>
      <GiftDispatchContext.Provider value={dispatch}>
        {children}
      </GiftDispatchContext.Provider>
    </GiftStateContext.Provider>
  );
};

const useGiftState = (): GiftState => {
  const context = React.useContext(GiftStateContext);
  if (context === undefined) {
    throw new Error('useGiftState must be used within a GiftProvider');
  }
  return context;
};

const useGiftDispatch = (): GiftDispatch => {
  const context = React.useContext(GiftDispatchContext);
  if (context === undefined) {
    throw new Error('useGiftDispatch must be used within a GiftProvider');
  }
  return context;
};

const useGift = (): [GiftState, GiftDispatch] => [
  useGiftState(),
  useGiftDispatch(),
];

export { GiftProvider, useGiftState, useGiftDispatch, useGift };
