import React from 'react';
import { useRouter } from 'next/router';

import { useAuth } from '@context/auth';
import {
  usePostBookingTodos,
  usePrefetchOkayCheckAnswers,
  usePrefetchOkayCheckQuestions,
  useSessionPricing,
} from '@services/session';

const TodosComplete: React.FunctionComponent<React.PropsWithChildren<any>> = ({
  children,
}) => {
  const router = useRouter();
  const { user } = useAuth();
  const { data: postBookingTodos } = usePostBookingTodos({ enabled: !!user });
  const { data: sessionPricing } = useSessionPricing({
    enabled: !!user,
  });

  const prefetchOkayCheckQuestions = usePrefetchOkayCheckQuestions();
  const prefetchOkayCheckAnswers = usePrefetchOkayCheckAnswers();

  React.useEffect(() => {
    if (
      postBookingTodos?.isOkayCheckDue &&
      postBookingTodos.actualSessionNumber > 1
    ) {
      prefetchOkayCheckQuestions();
      prefetchOkayCheckAnswers();
    }
  }, [
    postBookingTodos?.actualSessionNumber,
    postBookingTodos?.isOkayCheckDue,
    prefetchOkayCheckAnswers,
    prefetchOkayCheckQuestions,
  ]);

  if (
    postBookingTodos?.isPaymentMethodDue &&
    !router.pathname.includes('/payment-failed')
  ) {
    router.replace('/payment-failed');
    return <></>;
  }

  if (
    (postBookingTodos?.isWaiverDue ||
      postBookingTodos?.isNewGuestIntakeDue ||
      postBookingTodos?.isPartnerIntakeDue ||
      (postBookingTodos?.isOkayCheckDue &&
        postBookingTodos.actualSessionNumber <= 1) ||
      (sessionPricing?.tag !== 'selfPayRate' &&
        postBookingTodos?.isInsuranceDue)) &&
    !router.pathname.includes('/book-session/post-booking-steps') &&
    !router.pathname.includes('/payment-failed') &&
    !router.pathname.includes('/my-account/insurance')
  ) {
    router.replace('/book-session/post-booking-steps');
    return <></>;
  }

  if (
    postBookingTodos?.isOkayCheckDue &&
    postBookingTodos.actualSessionNumber > 1 &&
    !router.pathname.includes('/book-session/post-booking-steps') &&
    !router.pathname.includes('/payment-failed') &&
    !router.pathname.includes('/my-account/insurance') &&
    !router.pathname.includes('/therapists/review')
  ) {
    router.replace('/book-session/post-booking-steps/okay-check');
    return <></>;
  }

  if (
    postBookingTodos?.isTherapistReviewDue &&
    !router.pathname.includes('/therapists/review') &&
    !router.pathname.includes('/book-session/post-booking-steps') &&
    !router.pathname.includes('/payment-failed') &&
    !router.pathname.includes('/my-account/insurance') &&
    !router.pathname.includes('/refer/nudge') &&
    !router.pathname.includes('/my-sessions')
  ) {
    router.replace('/therapists/review');
    return <></>;
  }

  return children;
};

export default TodosComplete;
