import { queryCache } from 'react-query';
import dayjs, { Dayjs, UnitType } from 'dayjs';
import { FormikProps } from 'formik';
import Cookies from 'js-cookie';
import * as Yup from 'yup';

import { PromiseReject, PromiseResolve } from '@models/common/async-hook';
import { ErrorMessageError } from '@models/common/error';
import { BooleanRadioField } from '@models/session/new-guest-intake';
import {
  cookieTokenKey,
  guardianTriggerLimit,
  maxDaysForCalendar,
  monthFormatForCalendar,
} from '@utils/constants';

const parseErrorMessage = (error: ErrorMessageError): string | null => {
  if (error.response?.data?.message) {
    return error.response.data.message;
  }
  if (error.message) {
    return error.message;
  }
  if (typeof error === 'string') {
    return error;
  }

  return null;
};

const clearUserData = (): void => {
  Cookies.remove(cookieTokenKey);
  window?.localStorage?.clear();
  queryCache.clear();
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const isEmpty = (obj: any): boolean =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

const isBrowser = (): boolean => typeof window !== 'undefined';

type CAF = (args?: CAFArgs) => unknown;
type CAFArgs = unknown;

const callAll = (...fns: CAF[]) => (...args: CAFArgs[]): unknown[] =>
  fns.map(fn => fn && fn(...args));

const callAllEach = (...fns: CAF[]) => (...args: CAFArgs[]): void => {
  fns.forEach(fn => fn && fn(...args));
};

const pickBy = <T extends Record<string, any>>(
  object: T,
  includeFalse = false,
): Partial<T> | T => {
  const obj = {} as T | Partial<T>;

  Object.keys(object).forEach((key: keyof T) => {
    if (object[key] || (includeFalse ? object[key] === false : false)) {
      obj[key] = object[key];
    }
  });

  return obj;
};

const pick = (
  object: Record<string, any>,
  keys: string[],
): Partial<typeof object> =>
  keys.reduce((obj, key) => {
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {} as Partial<typeof object>);

const parseUrlSlug = (slug: string): string =>
  slug.toLowerCase().replace(/ /g, '-');

const difference = (arrays: Array<Array<unknown>>): Array<unknown> =>
  arrays.reduce((a, b) => a.filter(value => !b.includes(value)));

const dateDifference = (
  dateA: string | Date | Dayjs,
  dateB: string | Date | Dayjs,
  granularity: UnitType = 'year',
): number => dayjs(dateA).diff(dateB, granularity);

const upperFirst = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

const getAge = (date: string | Date | Dayjs): number =>
  dateDifference(dayjs(), date);

const isUnderGuardianAge = (date: string | Date | Dayjs): boolean =>
  getAge(date) < guardianTriggerLimit;

const filterByName = <T extends { firstName: string; lastName: string }>(
  list: T[] = [],
  query: string,
): T[] => {
  if (isEmpty(query) || !list) {
    return [...(list || [])];
  }

  const result = list.filter(item => {
    const name = `${item.firstName.toLowerCase()} ${item.lastName.toLowerCase()}`;
    return name.includes(query.toLowerCase().trim());
  });

  return result;
};

const pull = <T = string | number>(
  arr: Array<T>,
  ...removeList: Array<T>
): Array<T> => {
  const removeSet = new Set(removeList);
  return arr.filter(el => !removeSet.has(el));
};

const castArray = <T>(val: T | T[]): T[] => (Array.isArray(val) ? val : [val]);

const fixedSubmitForm = <T>({
  submitForm,
  validateForm,
}: FormikProps<T>): Promise<void> =>
  new Promise((resolve, reject) => {
    submitForm()
      .then(validateForm)
      .then(errors => {
        const isValid = Object.keys(errors).length === 0;
        if (isValid) {
          resolve();
        } else {
          reject();
        }
      })
      .catch(() => {
        reject();
      });
  });

const getDatesForCalendar = (): Array<{ id: number; date: string }> => {
  const datesArray = [];
  for (let i = 0; i < maxDaysForCalendar; i += 1) {
    const date = dayjs().add(i, 'day');
    datesArray.push({
      id: i,
      date: date.format('YYYY-MM-DD'),
    });
  }
  return datesArray;
};

const getMonthsForCalendar = (): string[] => {
  const currentMonth = dayjs().month();
  const maxMonth = dayjs()
    .add(maxDaysForCalendar - 1, 'day')
    .month();
  const months = [dayjs().format(monthFormatForCalendar)];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < maxMonth - currentMonth; i++) {
    months.push(
      dayjs()
        .add(i + 1, 'month')
        .format(monthFormatForCalendar),
    );
  }

  return months;
};

const checkKeyEnterOrSpace = (
  e: React.KeyboardEvent<HTMLDivElement>,
): boolean =>
  e.key === 'Enter' ||
  e.key === ' ' ||
  e.key === 'Spacebar' ||
  e.which === 13 ||
  e.which === 32;

const getDropdownOptionsFromEnum = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  enumObj: any,
): any =>
  Object.values(enumObj).map((val: typeof enumObj) => ({
    label: val,
    value: val,
  })) as any; // TODO: type this

const initializeDropdownValue = <R extends { label: string; value: string }>(
  key: string,
  values: Record<string, any>,
): R =>
  ({
    label: values[key],
    value: values[key],
  } as R);

const getBooleanRadioFieldValue = (
  value: boolean | null,
): BooleanRadioField | typeof value =>
  // eslint-disable-next-line no-nested-ternary
  value === true
    ? BooleanRadioField.yes
    : value === false
    ? BooleanRadioField.no
    : value;

const getDropdownValueValidationSchema = <T>(
  options: T,
): Yup.ObjectSchema<{ label: T; value: T }> =>
  Yup.object({
    label: Yup.mixed().oneOf(Object.values(options)).defined(),
    value: Yup.mixed().oneOf(Object.values(options)).defined(),
  }).nullable() as Yup.ObjectSchema<{ label: T; value: any }>;

const addCommasToNumber = (x: number | string): string =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const urlify = (text: string, style?: string): string => {
  // eslint-disable-next-line no-useless-escape
  const urlRegex = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;

  return text.replace(urlRegex, (url: string) => {
    let updatedUrl = url;

    if (!/^https?:\/\//i.test(updatedUrl)) {
      updatedUrl = `https://${updatedUrl}`;
    }

    return `<a style=${style} target="_blank" href="${updatedUrl}">${url}</a>`;
  });
};

const roundNumber = (num: number | string): string =>
  Number(num)
    .toFixed(2)
    .replace(/[.,]00$/, '');

const chunk = <T>(input: Array<T>, size: number): Array<T[]> =>
  input.reduce(
    (arr, item, idx) =>
      idx % size === 0
        ? [...arr, [item]]
        : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]],
    [] as Array<T[]>,
  );

const parseJwt = (
  token: string,
): { email: string; iat: number; tokenId: string } | null => {
  if (!token) {
    return null;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(atob(base64));
};

const parseTherapistNameSlug = (name = ''): string =>
  name?.trim().toLowerCase();

const deferredPromise = (): {
  promise: Promise<any>;
  resolve: PromiseResolve;
  reject: PromiseReject;
} => {
  let resolve: PromiseResolve;
  let reject: PromiseReject;

  const promise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });

  return { promise, resolve, reject };
};

const withHttps = (url: string): string =>
  !/^https?:\/\//i.test(url) ? `https://${url}` : url;

export {
  difference,
  parseErrorMessage,
  clearUserData,
  chunk,
  isEmpty,
  isBrowser,
  callAll,
  callAllEach,
  pickBy,
  parseUrlSlug,
  pick,
  dateDifference,
  upperFirst,
  getAge,
  isUnderGuardianAge,
  filterByName,
  pull,
  castArray,
  fixedSubmitForm,
  getDatesForCalendar,
  getMonthsForCalendar,
  checkKeyEnterOrSpace,
  getDropdownOptionsFromEnum,
  initializeDropdownValue,
  getBooleanRadioFieldValue,
  getDropdownValueValidationSchema,
  addCommasToNumber,
  urlify,
  roundNumber,
  parseJwt,
  parseTherapistNameSlug,
  deferredPromise,
  withHttps,
};
