export const base = 'white';
export const text = '#434449';
export const gray = '#f1f2f7';
export const gray10 = '#f1f1f4';
export const gray20 = '#e4e5e9';
export const gray40 = '#b6b8ba';
export const gray80 = '#6f7077';
export const indigo = '#3f51b5';
export const indigoDarken10 = '#364495';
export const indigoLighten80 = '#b7c1f8';
export const yellow = '#ffc107';
export const green = '#4caf50';
export const danger = '#D2394B';
export const orange = 'orange';

export const primary = '#15364C';
export const secondary = '#2F6079';
export const border = 'rgba(0,0,0,0.08)';
export const btnBorder = 'rgba(0,0,0,0.15)';
export const red = '#E47481';
export const phoneText = '#AAAFB2';
export const pink = '#E8D0CD';
export const lightPink = 'rgba(229,208,204,0.25)';
export const lightBlue = '#cddbe3';
export const lightBlue2 = '#E0E9EF';
export const extraLightBlue = 'rgba(204,219,228,0.22)';
export const homepagePink = '#F9F6F4';

export const white = '#ffffff';
export const black = 'black';
