import React from 'react';
import { useRouter } from 'next/router';

type History = Array<string>;

type HistoryContext = {
  history: History;
  setHistory: (history: React.SetStateAction<History>) => void;
};

const HistoryStateContext = React.createContext<HistoryContext | undefined>(
  undefined,
);

const HistoryProvider: React.FunctionComponent = ({ children }) => {
  const [history, setHistory] = React.useState<History>([]);
  const { asPath } = useRouter();

  React.useEffect(() => {
    if (history[history.length - 1] !== asPath) {
      setHistory([...history, asPath]);
    }
  }, [asPath, history]);

  const providerValue = React.useMemo(
    () => ({
      history,
      setHistory,
    }),
    [history],
  );

  return (
    <HistoryStateContext.Provider value={providerValue}>
      {children}
    </HistoryStateContext.Provider>
  );
};

const useHistory = (): HistoryContext => {
  const context = React.useContext(HistoryStateContext);
  if (context === undefined) {
    throw new Error('useHistoryState must be used within a HistoryProvider');
  }

  return context;
};

export { HistoryProvider, useHistory };
