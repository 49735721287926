import React from 'react';
import Script from 'next/script';

import { REFERRAL_LINK } from '@services/refer';

const BRANCH_KEY = process.env.NEXT_PUBLIC_BRANCH_API_KEY;

const ScriptComponent: React.FunctionComponent = () => (
  <>
    <Script src="/accessibe.js" />
    <Script
      src="/branchinit.js"
      onLoad={() => {
        window?.branch?.init(BRANCH_KEY, (_err: any, data: any) => {
          const link = data?.data_parsed?.[`~referring_link`];
          const { isReferralLink } = data?.data_parsed || {};

          if (link && Boolean(isReferralLink)) {
            window.localStorage.setItem(REFERRAL_LINK, link);
          }
        });
      }}
    />
    <Script src="/segment.js" strategy="lazyOnload" />
  </>
);

export const ZendeskChatScript: React.FC = () => (
  <Script
    id="ze-snippet"
    src="https://static.zdassets.com/ekr/snippet.js?key=862472cc-92dc-48e5-b8cc-9aeb9cd331f7"
  />
);

export default ScriptComponent;
