import { TransactionModel } from '@models/common/transaction';
import { BaseEntity } from '@models/entity';
import { Location } from '@models/location';
import { Therapist } from '@models/therapist';

export enum SessionStatus {
  UPCOMING = 'upcoming',
  COMPLETED = 'completed',
  NO_SHOW = 'no-show',
  CANCELED = 'canceled',
}
export interface Session extends BaseEntity {
  locationId: number;
  partnerId: number | null;
  reasonOther: string | null;
  serviceId: number;
  startDateTime: string;
  therapistId: number;
  userId: number;
  status: SessionStatus;
  therapist?: Partial<Therapist>;
  location?: Partial<Location>;
  transaction?: TransactionModel;
  membershipTransaction?: TransactionModel;
  isVirtual?: boolean;
}
