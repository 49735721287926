import React from 'react';

import usePersistentReducer from '@hooks/use-persistent-reducer';
import { SessionDispatch, SessionState } from '@models/session';
import sessionReducer, { sessionInitialState } from '@reducers/session';

const SessionStateContext = React.createContext<SessionState | undefined>(
  undefined,
);
const SessionDispatchContext = React.createContext<SessionDispatch | undefined>(
  undefined,
);

const SessionProvider: React.FunctionComponent = ({ children }) => {
  const [state, dispatch] = usePersistentReducer(
    sessionReducer,
    sessionInitialState,
    'session',
  );
  return (
    <SessionStateContext.Provider value={state}>
      <SessionDispatchContext.Provider value={dispatch}>
        {children}
      </SessionDispatchContext.Provider>
    </SessionStateContext.Provider>
  );
};

const useSessionState = (): SessionState => {
  const context = React.useContext(SessionStateContext);
  if (context === undefined) {
    throw new Error('useSessionState must be used within a SessionProvider');
  }
  return context;
};

const useSessionDispatch = (): SessionDispatch => {
  const context = React.useContext(SessionDispatchContext);
  if (context === undefined) {
    throw new Error('useSessionDispatch must be used within a SessionProvider');
  }
  return context;
};

const useSession = (): [SessionState, SessionDispatch] => [
  useSessionState(),
  useSessionDispatch(),
];

export { SessionProvider, useSessionState, useSessionDispatch, useSession };
