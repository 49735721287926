import { GiftDeliveryForm } from './gift-delivery';
import { GiftType } from './gift-type';

export interface GiftState {
  selectedGiftType: GiftType | null;
  selectedDuration: number | null;
  giftDeliveryInfo: GiftDeliveryForm | null;
  paymentMethodId: string | null;
}

export enum GiftActionTypes {
  SET_SELECTED_GIFT_TYPE = 'SET_SELECTED_GIFT_TYPE',
  SET_SELECTED_GIFT_DURATION = 'SET_SELECTED_GIFT_DURATION',
  SET_GIFT_DELIVERY_INFO = 'SET_GIFT_DELIVERY_INFO',
  SET_PAYMENT_METHOD_ID = 'SET_PAYMENT_METHOD_ID',
  RESET = 'RESET',
}

export type GiftActions =
  | {
      type: GiftActionTypes.SET_SELECTED_GIFT_TYPE;
      payload: GiftType;
    }
  | {
      type: GiftActionTypes.SET_SELECTED_GIFT_DURATION;
      payload: number | null;
    }
  | {
      type: GiftActionTypes.SET_GIFT_DELIVERY_INFO;
      payload: GiftDeliveryForm;
    }
  | {
      type: GiftActionTypes.SET_PAYMENT_METHOD_ID;
      payload: string;
    }
  | {
      type: GiftActionTypes.RESET;
    };

export type GiftReducerType = (
  state: GiftState,
  action: GiftActions,
) => GiftState;

export type GiftDispatch = React.Dispatch<GiftActions>;
