import AmexIcon from '@assets/images/svg/american-express.svg';
import DinersClubIcon from '@assets/images/svg/diners-club.svg';
import Discover from '@assets/images/svg/discover.svg';
import JcbIcon from '@assets/images/svg/jcb.svg';
import MasterCardIcon from '@assets/images/svg/mastercard.svg';
import UnionPayIcon from '@assets/images/svg/union-pay.svg';
import VisaIcon from '@assets/images/svg/visa.svg';

const cookieTokenKey = '__auth_token__';
const cookieUserIdKey = '__userId__';

const minAge = 13;
const guardianTriggerLimit = 18;
const minAllowedDate = 1930;

const clinicOpeningHour = 7;
const clinicClosingHour = 21;

const maxDaysForCalendar = 14;

const dateFormat = 'YYYY-MM-DD';
const monthFormatForCalendar = 'MMMM YYYY';

const dateFormatForTimes = 'YYYY-MM-DDTHH:mm:ss';

const helpEmail = 'help@okayhumans.com';

const helpPhone = '(855) 651-1580';

const cardIconMap = {
  visa: VisaIcon,
  mastercard: MasterCardIcon,
  'american-express': AmexIcon,
  diners: DinersClubIcon,
  discover: Discover,
  jcb: JcbIcon,
  unionpay: UnionPayIcon,
  amex: AmexIcon,
};

const CAREERS_URL = 'https://okayhumans.freshteam.com/jobs';

const ONE_HOUR = 60 * 60;

const THIRTY_MINUTES = 60 * 30;

const FIVE_MINUTES = 60 * 5;

const ONE_MINUTE = 60;

const SESSION_SUMMARY_DATE_FORMAT = 'MMMM Do, YYYY';

const SESSION_SUMMARY_TIME_FORMAT = '@ h:mma';

export {
  cookieTokenKey,
  cookieUserIdKey,
  minAge,
  guardianTriggerLimit,
  clinicOpeningHour,
  clinicClosingHour,
  minAllowedDate,
  maxDaysForCalendar,
  monthFormatForCalendar,
  dateFormat,
  dateFormatForTimes,
  helpEmail,
  helpPhone,
  cardIconMap,
  CAREERS_URL,
  ONE_HOUR,
  THIRTY_MINUTES,
  FIVE_MINUTES,
  ONE_MINUTE,
  SESSION_SUMMARY_DATE_FORMAT,
  SESSION_SUMMARY_TIME_FORMAT,
};
