import { Location } from '@models/location';
import { Slot } from '@models/slots';

import { SlotFilter } from './choose-therapist';
import { GuestInfoFormValue } from './guest-info';
import { Price } from './pricing';
import { TherapyReasons } from './select-reason';

export enum BookingFlows {
  LOCATION = 'location',
  THERAPIST = 'therapist',
}

export interface SessionState {
  bookingFlow: BookingFlows | null;
  pricePreSelected: boolean;
  selectedLocation: Location | null;
  isVirtualSession: boolean;
  reasonsForTherapy: TherapyReasons;
  accompanyingGuestInfo: GuestInfoFormValue | null;
  isSlotFiltersPristine: boolean;
  isTherapistFilterPristine: boolean;
  slotFilters: SlotFilter | null;
  selectedSlot: Slot | null;
  selectedPrice: Price | null;
}

export enum SessionActionTypes {
  SET_BOOKING_FLOW = 'SET_BOOKING_FLOW',
  SET_PRICE_PRE_SELECTION = 'SET_PRICE_PRE_SELECTION',
  SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION',
  SET_IS_VIRTUAL_SESSION = 'SET_IS_VIRTUAL_SESSION',
  SET_REASONS_FOR_THERAPY = 'SET_REASONS_FOR_THERAPY',
  SET_ACCOMPANYING_GUEST_INFO = 'SET_ACCOMPANYING_GUEST_INFO',
  CLEAR_ACCOMPANYING_GUEST_INFO = 'CLEAR_ACCOMPANYING_GUEST_INFO',
  SET_SLOT_FILTERS_PRISTINE = 'SET_SLOT_FILTERS_PRISTINE',
  SET_THERAPIST_FILTER_PRISTINE = 'SET_THERAPIST_FILTER_PRISTINE',
  SET_SLOT_FILTERS = 'SET_SLOT_FILTERS',
  CLEAR_SLOT_FILTERS = 'CLEAR_SLOT_FILTERS',
  SET_SELECT_SLOT = 'SET_SELECT_SLOT',
  SET_SELECTED_PRICE = 'SET_SELECTED_PRICE',
  RESET = 'RESET',
}

export type SessionActions =
  | {
      type: SessionActionTypes.SET_BOOKING_FLOW;
      payload: BookingFlows;
    }
  | {
      type: SessionActionTypes.SET_PRICE_PRE_SELECTION;
      payload: boolean;
    }
  | {
      type: SessionActionTypes.SET_SELECTED_LOCATION;
      payload: Location;
    }
  | {
      type: SessionActionTypes.SET_IS_VIRTUAL_SESSION;
      payload: boolean;
    }
  | {
      type: SessionActionTypes.SET_REASONS_FOR_THERAPY;
      payload: TherapyReasons;
    }
  | {
      type: SessionActionTypes.SET_ACCOMPANYING_GUEST_INFO;
      payload: GuestInfoFormValue;
    }
  | {
      type: SessionActionTypes.CLEAR_ACCOMPANYING_GUEST_INFO;
    }
  | {
      type: SessionActionTypes.SET_SLOT_FILTERS_PRISTINE;
      payload: boolean;
    }
  | {
      type: SessionActionTypes.SET_THERAPIST_FILTER_PRISTINE;
      payload: boolean;
    }
  | {
      type: SessionActionTypes.SET_SLOT_FILTERS;
      payload: SlotFilter;
    }
  | {
      type: SessionActionTypes.CLEAR_SLOT_FILTERS;
    }
  | {
      type: SessionActionTypes.SET_SELECT_SLOT;
      payload: Slot;
    }
  | {
      type: SessionActionTypes.SET_SELECTED_PRICE;
      payload: Price;
    }
  | {
      type: SessionActionTypes.RESET;
    };

export type SessionReducerType = (
  state: SessionState,
  action: SessionActions,
) => SessionState;

export type SessionDispatch = React.Dispatch<SessionActions>;
