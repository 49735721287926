import { ErrorType } from '@models/common/async-hook';
import { DropdownOrRadioFieldOption } from '@models/common/form';
import { MultiStepFormState } from '@models/common/multi-step-form';
import { USState, USStateOption } from '@models/common/states';
import { BaseEntity } from '@models/entity';

import { MultistepFormStep } from './common';
import { PostBookingTodos } from './post-booking-flow';

export interface NewGuestIntake {
  address: string;
  city: string;
  state: USState;
  zip: string;
  preferredName: string;
  employmentStatus: GuestIntakeEmploymentStatus;
  relationshipStatus: GuestIntakeRelationshipStatus;
  identifiedGender: GuestIntakeIdentifiedGender & string;
  identifiedGenderOther?: string;
  genderPronouns: GuestIntakeGenderPronouns & string;
  genderPronounsOther?: string;
  therapyReason: string;
  therapyGain: string;
  therapyAnythingElse: string;
  emergencyFirstName: string;
  emergencyLastName: string;
  emergencyPhoneNumber: string;
  emergencyRelationship:
    | GuestIntakeEmergencyRelationship
    | GuestIntakeMinorEmergencyRelationship;
  referrer: GuestIntakeReferrer & string;
  referrerOther?: string;
  hasConnection: boolean;
  connectionName?: string;
  familyConcerns: GuestIntakeFamilyConcern[];
  familyConcernOther?: string;
  hasOnlineUsageConcern: boolean;
  hasMatureContentExposureConcern: boolean;
  partnerIntake: NewGuestPartnerIntake;
  currentGuestIntakeStep: number;
}

export interface NewGuestPartnerIntake {
  relationships: GuestIntakePartnerRelationship[];
  isHappyWithSexFrequency: boolean;
  sexualRelationshipEnjoyability: GuestIntakePartnerSexualRelationshipEnjoyability;
  partnershipLength: GuestIntakePartnershipLength;
  primaryReasonFrequency: GuestIntakePartnerPrimaryReasonFrequency;
  primaryReasonConcern: GuestIntakePartnerPrimaryReasonConcern;
  difficultyEfforts: string;
  partnershipStrengths: string;
  partnershipHappinessLevel: GuestIntakePartnershipHappinessLevel;
  relationshipConcernOne: string;
  relationshipConcernTwo: string;
  relationshipConcernThree: string;
  relationshipImprovementSuggestions: string;
  hasPartnerSubstanceAbuseConcern: boolean;
  hasPhysicallyAbusedPartner: boolean;
}

export type NewGuestIntakeForm = Omit<
  NewGuestIntake,
  | 'state'
  | 'employmentStatus'
  | 'relationshipStatus'
  | 'identifiedGender'
  | 'genderPronouns'
  | 'emergencyRelationship'
  | 'referrer'
  | 'hasConnection'
  | 'familyConcerns'
  | 'hasOnlineUsageConcern'
  | 'hasMatureContentExposureConcern'
  | 'partnerIntake'
  | 'currentGuestIntakeStep'
> & {
  state: USStateOption;
  employmentStatus: GuestIntakeEmploymentStatusOption;
  relationshipStatus: GuestIntakeRelationshipStatusOption;
  identifiedGender: GuestIntakeIdentifiedGenderOption;
  genderPronouns: GuestIntakeGenderPronounsOption;
  emergencyRelationship:
    | GuestIntakeEmergencyRelationshipOption
    | GuestIntakeMinorEmergencyRelationshipOption;
  referrer: GuestIntakeReferrerOption;
  hasConnection: BooleanRadioField;
  familyConcerns: GuestIntakeFamilyConcernOption[];
  hasOnlineUsageConcern: BooleanRadioField;
  hasMatureContentExposureConcern: BooleanRadioField;
  partnerIntake: NewGuestPartnerIntakeForm;
};

export type NewGuestPartnerIntakeForm = Omit<
  NewGuestPartnerIntake,
  | 'relationships'
  | 'isHappyWithSexFrequency'
  | 'sexualRelationshipEnjoyability'
  | 'partnershipLength'
  | 'primaryReasonFrequency'
  | 'primaryReasonConcern'
  | 'partnershipHappinessLevel'
  | 'hasPartnerSubstanceAbuseConcern'
  | 'hasPhysicallyAbusedPartner'
> & {
  relationships: GuestIntakePartnerRelationship[];
  isHappyWithSexFrequency: BooleanRadioField;
  sexualRelationshipEnjoyability: GuestIntakePartnerSexualRelationshipEnjoyabilityOption;
  partnershipLength: GuestIntakePartnershipLengthOption;
  primaryReasonFrequency: GuestIntakePartnerPrimaryReasonFrequencyOption;
  primaryReasonConcern: GuestIntakePartnerPrimaryReasonConcernOption;
  partnershipHappinessLevel: GuestIntakePartnershipHappinessLevelOption;
  hasPartnerSubstanceAbuseConcern: BooleanRadioField;
  hasPhysicallyAbusedPartner: BooleanRadioField;
};

export interface NewGuestIntakeApiResponse extends NewGuestIntake, BaseEntity {
  userId: number;
}

export enum BooleanRadioField {
  yes = 'YES',
  no = 'NO',
}
export type BooleanRadioFieldOption = DropdownOrRadioFieldOption<
  BooleanRadioField
>;

export enum GuestIntakeEmploymentStatus {
  happilyEmployed = 'Happily Employed',
  unhappilyEmployed = 'Unhappily Employed',
  student = 'Student',
  retired = 'Retired',
  unemployed = 'Unemployed',
  NA = 'N/A',
}
export enum GuestIntakeEmploymentStatusMinor {
  happilyEmployed = 'Happily Employed',
  unhappilyEmployed = 'Unhappily Employed',
  student = 'Student',
  unemployed = 'Unemployed',
  NA = 'N/A',
}
export type GuestIntakeEmploymentStatusOption = DropdownOrRadioFieldOption<
  GuestIntakeEmploymentStatus | GuestIntakeEmploymentStatusMinor
>;

export enum GuestIntakeRelationshipStatus {
  singleAndSatisfied = 'Single & Satisfied',
  singleAndDissatisfied = 'Single & Dissatisfied',
  legallyMarried = 'Legally Married',
  satisfiedInRelationship = 'Satisfied in a Relationship',
  dissatisfiedInRelationship = 'Dissatisfied in a Relationship',
  NA = 'N/A',
}
export enum GuestIntakeRelationshipStatusMinor {
  singleAndSatisfied = 'Single & Satisfied',
  singleAndDissatisfied = 'Single & Dissatisfied',
  satisfiedInRelationship = 'Satisfied in a Relationship',
  dissatisfiedInRelationship = 'Dissatisfied in a Relationship',
  NA = 'N/A',
}
export type GuestIntakeRelationshipStatusOption = DropdownOrRadioFieldOption<
  GuestIntakeRelationshipStatus | GuestIntakeRelationshipStatusMinor
>;

export enum GuestIntakeIdentifiedGender {
  man = 'Man',
  woman = 'Woman',
  transMan = 'Trans Man',
  transWoman = 'Trans Woman',
  genderQueer = 'Genderqueer / Gender Non-Conforming / Non-Binary / Agender',
  questioning = 'Questioning',
  preferNotToSay = 'Prefer Not to Say',
  other = 'Prefer to Self Describe',
}
export type GuestIntakeIdentifiedGenderOption = DropdownOrRadioFieldOption<
  GuestIntakeIdentifiedGender
>;

export enum GuestIntakeGenderPronouns {
  female = 'She / Her / Hers',
  male = 'He / Him / His',
  name = 'Just my name',
  they = 'They / Them / Theirs',
  ze = 'Ze / Hir',
  other = 'Other',
}
export type GuestIntakeGenderPronounsOption = DropdownOrRadioFieldOption<
  GuestIntakeGenderPronouns
>;

export enum GuestIntakeEmergencyRelationship {
  spouse = 'Spouse',
  partner = 'Partner',
  parent = 'Parent',
  sibling = 'Sibling',
  friend = 'Friend',
}
export type GuestIntakeEmergencyRelationshipOption = DropdownOrRadioFieldOption<
  GuestIntakeEmergencyRelationship
>;

export enum GuestIntakeMinorEmergencyRelationship {
  parent = 'Parent',
  guardian = 'Guardian',
  sibling = 'Sibling',
  friend = 'Friend',
}
export type GuestIntakeMinorEmergencyRelationshipOption = DropdownOrRadioFieldOption<
  GuestIntakeMinorEmergencyRelationship
>;

export enum GuestIntakeReferrer {
  instagram = 'Instagram',
  facebook = 'Facebook',
  friendOrFamily = 'Friend or Family',
  searchEngine = 'Search Engine',
  yelp = 'Yelp',
  doctorReferral = 'Referred by Dr.',
  other = 'Other',
}
export type GuestIntakeReferrerOption = DropdownOrRadioFieldOption<
  GuestIntakeReferrer
>;

export enum GuestIntakeFamilyConcern {
  fighting = 'Fighting',
  divorce = 'Divorce / Separation / Blended Family',
  death = 'Death of a loved one',
  alcoholAbuse = 'Alcohol or drug abuse by a family member',
  other = 'Other',
}
export type GuestIntakeFamilyConcernOption = DropdownOrRadioFieldOption<
  GuestIntakeFamilyConcern
>;

// partner fields
export enum GuestIntakePartnerRelationship {
  married = 'Married',
  separated = 'Separated',
  divorced = 'Divorced',
  coParenting = 'Co-parenting',
  dating = 'Dating',
  guardianOrMinor = 'Guardian / Minor',
  siblings = 'Siblings',
  friends = 'Friends',
  businessPartners = 'Business partners',
}

export enum GuestIntakePartnerSexualRelationshipEnjoyability {
  notEnjoyable = 'Not enjoyable at all',
  rarelyEnjoyable = 'Rarely Enjoyable',
  mostlyEnjoyable = 'Mostly Enjoyable',
  veryEnjoyable = 'Very Enjoyable',
}
export type GuestIntakePartnerSexualRelationshipEnjoyabilityOption = DropdownOrRadioFieldOption<
  GuestIntakePartnerSexualRelationshipEnjoyability
>;

export enum GuestIntakePartnershipLength {
  lessThanAYear = 'Less than a year',
  oneToFourYears = '1 - 4 years',
  fiveToTenYears = '5 - 10 years',
  tenYearsOrMore = '10 years or more',
}
export type GuestIntakePartnershipLengthOption = DropdownOrRadioFieldOption<
  GuestIntakePartnershipLength
>;

export enum GuestIntakePartnerPrimaryReasonFrequency {
  noOccurrence = 'No occurrence to rarely',
  sometimes = 'Occurs sometimes',
  frequently = 'Occurs frequently',
  always = 'Occurs nearly always',
}
export type GuestIntakePartnerPrimaryReasonFrequencyOption = DropdownOrRadioFieldOption<
  GuestIntakePartnerPrimaryReasonFrequency
>;

export enum GuestIntakePartnerPrimaryReasonConcern {
  serious = 'Serious concern',
  moderate = 'Moderate concern',
  zero = 'Zero to little concern',
}
export type GuestIntakePartnerPrimaryReasonConcernOption = DropdownOrRadioFieldOption<
  GuestIntakePartnerPrimaryReasonConcern
>;

export enum GuestIntakePartnershipHappinessLevel {
  notHappy = 'Not happy at all',
  rarelyHappy = 'Rarely happy',
  mostlyHappy = 'Mostly happy',
  veryHappy = 'Very happy',
}
export type GuestIntakePartnershipHappinessLevelOption = DropdownOrRadioFieldOption<
  GuestIntakePartnershipHappinessLevel
>;

export type NewGuestIntakeFormGuestInfo = Pick<
  NewGuestIntakeForm,
  'address' | 'city' | 'state' | 'zip'
>;

export type NewGuestIntakeFormAboutYou = Pick<
  NewGuestIntakeForm,
  'preferredName' | 'employmentStatus' | 'relationshipStatus'
>;

export interface NewGuestIntakeFormAboutYouProps {
  employmentStatuses: GuestIntakeEmploymentStatusOption[];
  relationshipStatuses: GuestIntakeRelationshipStatusOption[];
}

export type NewGuestIntakeFormGender = Pick<
  NewGuestIntakeForm,
  | 'identifiedGender'
  | 'identifiedGenderOther'
  | 'genderPronouns'
  | 'genderPronounsOther'
>;

export interface NewGuestIntakeFormGenderProps {
  identifiedGenderOptions: GuestIntakeIdentifiedGenderOption[];
  genderPronounsOptions: GuestIntakeGenderPronounsOption[];
}

export type NewGuestIntakeFormGoals = Pick<
  NewGuestIntakeForm,
  'therapyReason' | 'therapyGain' | 'therapyAnythingElse'
>;

export type NewGuestIntakeFormEmergencyContact = Pick<
  NewGuestIntakeForm,
  | 'emergencyFirstName'
  | 'emergencyLastName'
  | 'emergencyPhoneNumber'
  | 'emergencyRelationship'
>;

export interface NewGuestIntakeFormEmergencyContactProps {
  emergencyRelationshipOptions:
    | GuestIntakeEmergencyRelationshipOption[]
    | GuestIntakeMinorEmergencyRelationshipOption[];
}

export type NewGuestIntakeFormAboutUs = Pick<
  NewGuestIntakeForm,
  'referrer' | 'referrerOther' | 'hasConnection' | 'connectionName'
>;

export interface NewGuestIntakeFormAboutUsProps {
  referrerOptions: GuestIntakeReferrerOption[];
  connectionOptions: BooleanRadioFieldOption[];
}

export type NewGuestIntakeFormFamilyInfo = Pick<
  NewGuestIntakeForm,
  | 'familyConcerns'
  | 'familyConcernOther'
  | 'hasOnlineUsageConcern'
  | 'hasMatureContentExposureConcern'
>;
export interface NewGuestIntakeFormFamilyInfoProps {
  familyConcernOptions: GuestIntakeFamilyConcernOption[];
  onlineUsageConcernOptions: BooleanRadioFieldOption[];
  matureContentExposureConcernOptions: BooleanRadioFieldOption[];
}

export type NewGuestPartnerIntakeFormRelationship = Record<
  Extract<keyof NewGuestIntakeForm, 'partnerIntake'>,
  Pick<NewGuestPartnerIntakeForm, 'relationships'>
>;
export interface NewGuestPartnerIntakeRelationshipProps {
  relationshipOptions: GuestIntakePartnerRelationship[];
}

export type NewGuestPartnerIntakeFormSexualRelationship = Record<
  Extract<keyof NewGuestIntakeForm, 'partnerIntake'>,
  Pick<
    NewGuestPartnerIntakeForm,
    'isHappyWithSexFrequency' | 'sexualRelationshipEnjoyability'
  >
>;
export interface NewGuestPartnerIntakeSexualRelationshipProps {
  sexFrequencyOptions: BooleanRadioFieldOption[];
  sexualRelationshipEnjoyabilityOptions: GuestIntakePartnerSexualRelationshipEnjoyabilityOption[];
}

export type NewGuestPartnerIntakeFormRelationshipHistory = Record<
  Extract<keyof NewGuestIntakeForm, 'partnerIntake'>,
  Pick<
    NewGuestPartnerIntakeForm,
    'partnershipLength' | 'primaryReasonFrequency' | 'primaryReasonConcern'
  >
>;
export interface NewGuestPartnerIntakeRelationshipHistoryProps {
  partnershipLengthOptions: GuestIntakePartnershipLengthOption[];
  primaryReasonFrequencyOptions: GuestIntakePartnerPrimaryReasonFrequencyOption[];
  primaryReasonConcernOptions: GuestIntakePartnerPrimaryReasonConcernOption[];
}

export type NewGuestPartnerIntakeFormDifficulties = Record<
  Extract<keyof NewGuestIntakeForm, 'partnerIntake'>,
  Pick<
    NewGuestPartnerIntakeForm,
    'difficultyEfforts' | 'partnershipStrengths' | 'partnershipHappinessLevel'
  >
>;
export interface NewGuestPartnerIntakeDifficultiesProps {
  partnershipHappinessLevelOptions: GuestIntakePartnershipHappinessLevelOption[];
}

export type NewGuestPartnerIntakeFormFewMoreThings = Record<
  Extract<keyof NewGuestIntakeForm, 'partnerIntake'>,
  Pick<
    NewGuestPartnerIntakeForm,
    | 'relationshipConcernOne'
    | 'relationshipConcernTwo'
    | 'relationshipConcernThree'
  >
>;

export type NewGuestPartnerIntakeFormAlmostDone = Record<
  Extract<keyof NewGuestIntakeForm, 'partnerIntake'>,
  Pick<
    NewGuestPartnerIntakeForm,
    | 'relationshipImprovementSuggestions'
    | 'hasPartnerSubstanceAbuseConcern'
    | 'hasPhysicallyAbusedPartner'
  >
>;
export interface NewGuestPartnerIntakeAlmostDoneProps {
  substanceAbuseConcernOptions: BooleanRadioFieldOption[];
  hasPhysicallyAbusedConcernOptions: BooleanRadioFieldOption[];
}

export type GuestIntakeFieldsForStep = Exclude<
  keyof NewGuestIntakeForm,
  'partnerIntake'
>;

export type PartnerFields =
  | 'partnerIntake.relationships'
  | 'partnerIntake.isHappyWithSexFrequency'
  | 'partnerIntake.sexualRelationshipEnjoyability'
  | 'partnerIntake.partnershipLength'
  | 'partnerIntake.primaryReasonFrequency'
  | 'partnerIntake.primaryReasonConcern'
  | 'partnerIntake.difficultyEfforts'
  | 'partnerIntake.partnershipStrengths'
  | 'partnerIntake.partnershipHappinessLevel'
  | 'partnerIntake.relationshipConcernOne'
  | 'partnerIntake.relationshipConcernTwo'
  | 'partnerIntake.relationshipConcernThree'
  | 'partnerIntake.relationshipImprovementSuggestions'
  | 'partnerIntake.hasPartnerSubstanceAbuseConcern'
  | 'partnerIntake.hasPhysicallyAbusedPartner';

export type FieldsByStep = Array<
  Array<GuestIntakeFieldsForStep> | Array<PartnerFields>
>;

export type FieldStep =
  | (keyof NewGuestIntakeForm)[]
  | (keyof NewGuestIntakeForm & keyof NewGuestPartnerIntakeForm)[];

export type GuestIntakeDropdownFields = keyof Pick<
  NewGuestIntake,
  | 'state'
  | 'employmentStatus'
  | 'relationshipStatus'
  | 'identifiedGender'
  | 'genderPronouns'
  | 'emergencyRelationship'
  | 'referrer'
>;

export type GuestIntakePartnerDropdownFields = keyof Pick<
  NewGuestPartnerIntake,
  | 'sexualRelationshipEnjoyability'
  | 'partnershipLength'
  | 'primaryReasonFrequency'
  | 'primaryReasonConcern'
  | 'partnershipHappinessLevel'
>;

export type DropdownFieldsForSubmit = Array<{
  field:
    | GuestIntakeDropdownFields
    | 'partnerIntake.sexualRelationshipEnjoyability'
    | 'partnerIntake.partnershipLength'
    | 'partnerIntake.primaryReasonFrequency'
    | 'partnerIntake.primaryReasonConcern'
    | 'partnerIntake.partnershipHappinessLevel';
  otherPresent: boolean;
  otherKey?: keyof Pick<
    NewGuestIntakeForm,
    'identifiedGenderOther' | 'genderPronounsOther' | 'referrerOther'
  >;
  otherOptionValue?:
    | GuestIntakeIdentifiedGender.other
    | GuestIntakeGenderPronouns.other
    | GuestIntakeReferrer.other;
}>;

export type MultiSelectDropdownFieldsForSubmit = Array<{
  field: keyof Pick<NewGuestIntake, 'familyConcerns'>;
  otherPresent: boolean;
  otherKey?: keyof Pick<NewGuestIntakeForm, 'familyConcernOther'>;
  otherOptionValue?: GuestIntakeFamilyConcern.other;
}>;

export type GuestIntakeRadioFields = keyof Pick<
  NewGuestIntake,
  'hasConnection' | 'hasOnlineUsageConcern' | 'hasMatureContentExposureConcern'
>;

export type GuestIntakePartnerRadioFields = keyof Pick<
  NewGuestPartnerIntake,
  | 'isHappyWithSexFrequency'
  | 'hasPartnerSubstanceAbuseConcern'
  | 'hasPhysicallyAbusedPartner'
>;

export type BooleanRadioFieldsForSubmit = Array<{
  field:
    | GuestIntakeRadioFields
    | 'partnerIntake.isHappyWithSexFrequency'
    | 'partnerIntake.hasPartnerSubstanceAbuseConcern'
    | 'partnerIntake.hasPhysicallyAbusedPartner';
  truthyOption: BooleanRadioField.yes;
  falsyOption: BooleanRadioField.no;
  dependentQuestionKey?: keyof Pick<NewGuestIntakeForm, 'connectionName'>;
  dependentQuestionOption?: BooleanRadioField;
}>;

export type UseGuestIntakeHookReturn = {
  multiStepState: {
    state: MultiStepFormState<NewGuestIntake>;
    previous: () => void;
  };
  result: {
    error: ErrorType;
    isError: boolean;
  };
  postBookingTodos: PostBookingTodos | undefined;
  steps: number;
  guestIntakeGuestInfo: MultistepFormStep<NewGuestIntakeFormGuestInfo>;
  guestIntakeAboutYou: MultistepFormStep<NewGuestIntakeFormAboutYou> &
    NewGuestIntakeFormAboutYouProps;
  guestIntakeGender: MultistepFormStep<NewGuestIntakeFormGender> &
    NewGuestIntakeFormGenderProps;
  guestIntakeGoals: MultistepFormStep<NewGuestIntakeFormGoals>;
  guestIntakeEmergencyContact: MultistepFormStep<
    NewGuestIntakeFormEmergencyContact
  > &
    NewGuestIntakeFormEmergencyContactProps;
  guestIntakeAboutUs: MultistepFormStep<NewGuestIntakeFormAboutUs> &
    NewGuestIntakeFormAboutUsProps;
  guestIntakeMinorFamilyInfo: MultistepFormStep<NewGuestIntakeFormFamilyInfo> &
    NewGuestIntakeFormFamilyInfoProps;
  guestIntakePartnerRelationship: MultistepFormStep<
    NewGuestPartnerIntakeFormRelationship
  > &
    NewGuestPartnerIntakeRelationshipProps;
  guestIntakePartnerSexualRelationship: MultistepFormStep<
    NewGuestPartnerIntakeFormSexualRelationship
  > &
    NewGuestPartnerIntakeSexualRelationshipProps;
  guestIntakePartnerRelationshipHistory: MultistepFormStep<
    NewGuestPartnerIntakeFormRelationshipHistory
  > &
    NewGuestPartnerIntakeRelationshipHistoryProps;
  guestIntakePartnerDifficulties: MultistepFormStep<
    NewGuestPartnerIntakeFormDifficulties
  > &
    NewGuestPartnerIntakeDifficultiesProps;
  guestIntakePartnerFewMoreThings: MultistepFormStep<
    NewGuestPartnerIntakeFormFewMoreThings
  >;
  guestIntakePartnerAlmostDone: MultistepFormStep<
    NewGuestPartnerIntakeFormAlmostDone
  > &
    NewGuestPartnerIntakeAlmostDoneProps;
  onSubmit: (values: NewGuestIntakeForm) => Promise<any>;
};

export interface HelplineNumbersComponentProps {
  handleClick: () => void;
}
