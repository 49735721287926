import {
  SessionActionTypes,
  SessionReducerType,
  SessionState,
} from '@models/session';

export const sessionInitialState: SessionState = {
  bookingFlow: null,
  pricePreSelected: false,
  selectedLocation: null,
  isVirtualSession: false,
  reasonsForTherapy: { options: [], other: null },
  accompanyingGuestInfo: null,
  isSlotFiltersPristine: true,
  isTherapistFilterPristine: true,
  slotFilters: null,
  selectedSlot: null,
  selectedPrice: null,
};

const sessionReducer: SessionReducerType = (state, action) => {
  switch (action.type) {
    case SessionActionTypes.SET_BOOKING_FLOW:
      return {
        ...state,
        bookingFlow: action.payload,
      };

    case SessionActionTypes.SET_PRICE_PRE_SELECTION:
      return {
        ...state,
        pricePreSelected: action.payload,
      };

    case SessionActionTypes.SET_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: action.payload,
      };

    case SessionActionTypes.SET_IS_VIRTUAL_SESSION:
      return {
        ...state,
        isVirtualSession: action.payload,
      };

    case SessionActionTypes.SET_REASONS_FOR_THERAPY:
      return {
        ...state,
        reasonsForTherapy: action.payload,
      };

    case SessionActionTypes.SET_ACCOMPANYING_GUEST_INFO:
      return {
        ...state,
        accompanyingGuestInfo: action.payload,
      };

    case SessionActionTypes.CLEAR_ACCOMPANYING_GUEST_INFO:
      return {
        ...state,
        accompanyingGuestInfo: sessionInitialState.accompanyingGuestInfo,
      };

    case SessionActionTypes.SET_SLOT_FILTERS_PRISTINE:
      return {
        ...state,
        isSlotFiltersPristine: action.payload,
      };

    case SessionActionTypes.SET_THERAPIST_FILTER_PRISTINE:
      return {
        ...state,
        isTherapistFilterPristine: action.payload,
      };

    case SessionActionTypes.SET_SLOT_FILTERS:
      return {
        ...state,
        slotFilters: action.payload,
      };

    case SessionActionTypes.CLEAR_SLOT_FILTERS:
      return {
        ...state,
        slotFilters: sessionInitialState.slotFilters,
      };

    case SessionActionTypes.SET_SELECT_SLOT:
      return {
        ...state,
        selectedSlot: action.payload,
      };

    case SessionActionTypes.SET_SELECTED_PRICE:
      return {
        ...state,
        selectedPrice: action.payload,
      };

    case SessionActionTypes.RESET:
      return sessionInitialState;

    default:
      return state;
  }
};

export default sessionReducer;
