/* eslint-disable class-methods-use-this */
import '../bootstrap';

import React from 'react';
import App from 'next/app';
import {} from '@emotion/core';

import Analytics from '@components/analytics';
import Scripts from '@components/script';
import TodosComplete from '@components/todos-complete';
import AppProviders from '@context/index';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@reach/tabs/styles.css';
import '@styles/slick.css';
import '@styles/sign-waiver.css';
import '@styles/font.css';
import '@styles/animation.css';
import '@styles/stripe.css';

declare global {
  interface Window {
    branch: any;
    analytics: any;
  }
}

export default class CustomApp extends App {
  render(): JSX.Element {
    const { Component, pageProps } = this.props;

    return (
      <>
        <Scripts />
        <AppProviders>
          <Analytics>
            <TodosComplete>
              <Component {...pageProps} />
            </TodosComplete>
          </Analytics>
        </AppProviders>
      </>
    );
  }
}
